.desktop-menu {
    display: none;
  }
  
  .mobile-menu {
    display: block;
  }
  
  /* This is a basic example, you can adjust breakpoints as needed */
  @media (min-width: 768px) {
    .desktop-menu {
      display: block;
    }
    .mobile-menu {
      display: none;
    }
  }

  /* Assuming default height for desktop is 64px */
.AppBar {
    height: 64px;
  }
  
  @media (max-width: 768px) {
    .AppBar {
      height: 50px;
    }
  }
  

  @media (max-width: 768px) {
    .desktop-menu, 
    .desktop-auth {
      display: none;
    }
  
    .mobile-menu {
      display: block;
    }
  }
  
  @media (min-width: 769px) {
    .desktop-menu,
    .desktop-auth {
      display: flex;
    }
  
    .mobile-menu {
      display: none;
    }
  }
  